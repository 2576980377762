<template>
    <modal ref="modalEntregarPedido" titulo="Entregar el pedido completo" no-aceptar adicional="Entregar Completo" @adicional="setEntrega">
        <div class="row mx-0 text-general justify-center">
            <div class="col-11">
                Varios de los productos se entregarán en diferentes rutas. Si entrega el pedido completo el pedido parcial desaparecerá de las rutas asignadas.
            </div>
            <div class="col-11 mt-3">
                ¿Desea entregar el pedido completo?
            </div>
            <div class="col-11 mt-5">
                Rutas donde se encuentra este mismo pedido
            </div>
            <div class="col-11 mt-3 mb-3">
                <div v-for="(r,index) in rutas" :key="index" class="row my-1">
                    <div class="col-8">
                        <i class="icon-calendar-empty text-general2" />
                        Turno {{ r.id_turno }} del {{ r.fecha_dia | helper-fecha('DD MMM Y') }}
                    </div>
                    <div class="col-4">
                        <i class="icon-route text-general2" />
                        Ruta {{ r.id_ruta }}
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Routing from '~/services/routing/rutas'
export default {
    data(){
        return{
            id_pedido:null,
            rutas:[]
        }
    },
    computed:{
        id_ruta(){
            return Number(this.$route.params.id_ruta)
        }
    },
    methods: {
        async toggle(id_pedido){
            this.id_pedido = id_pedido
            this.rutas = []
            await this.getOtrasRutas()
            if(this.rutas.length){
                this.$refs.modalEntregarPedido.toggle();
            }else{
                await this.setEntrega()
            }
        },
        async getOtrasRutas(){
            try {
                const params = {
                    id_ruta:this.id_ruta,
                    id_pedido:this.id_pedido
                }
                const {data} = await Routing.getOtrasRutas(params)
                this.rutas = data.rutas

            } catch (e){
                this.error_catch(e)
            }
        },
        async setEntrega(){
            try {
                const form = {
                    id_ruta:this.id_ruta,
                    id_pedido:this.id_pedido
                }
                const {data} = await Routing.setEntregaCompleta(form)
                if(this.rutas.length){
                    this.$refs.modalEntregarPedido.toggle();
                }
                this.notificacion('Ruta actualizada','se ha añadido el pedido completo','success')
                this.$emit('update')


            } catch (e){
                this.error_catch(e)
            }
        }

    }
}
</script>
